// HeroSection.js
import React from "react";

import heroBg from "../../../img/bg.svg";
import heroIllus from "../../../img/introIllus.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section
      className="relative flex h-screen flex-col items-center justify-center gap-5 bg-cover bg-center"
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="container flex w-full items-center justify-between">
        <div className="flex flex-1 flex-col gap-9">
          <h1 className="text-[44px] font-extrabold leading-snug tracking-wide text-[#1e212c]">
            MaktabHub - Xususiy Maktablar Haqida <br /> Bilib Oling!
          </h1>
          <div className="flex items-center gap-6">
            <Link
              to={"/"}
              className="flex flex-col items-center justify-start gap-2.5 rounded-full border border-[#1089ff] px-10"
            >
              <span className="text-center text-base font-bold leading-[52px] tracking-wide text-[#1089ff]">
                Biz Haqimizda
              </span>
            </Link>
            <Link
              to={"/"}
              className="inline-flex h-[52px] flex-col items-center justify-start gap-2.5 rounded-full bg-[#1089ff] px-10"
            >
              <span className="text-center text-base font-bold leading-[52px] tracking-wide text-white">
                Maktablarni ko‘rish
              </span>
            </Link>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-end">
          <img
            className="w-full max-w-[490px]"
            src={heroIllus}
            alt="Hero Illustration"
          />
        </div>
      </div>
      <div className="container flex items-center gap-16">
        <div className="flex items-center gap-3">
          <span className="text-center text-[46px] font-black leading-[59.80px] text-[#1e212c]">
            500+
          </span>
          <h2 className="text-base font-normal leading-relaxed text-[#1e212c]">
            O‘zbekistondagi Xususiy Maktablar
          </h2>
        </div>
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" fill="#1089ff" />
        </svg>
        <div className="flex items-center gap-3">
          <span className="text-center text-[46px] font-black leading-[59.80px] text-[#1e212c]">
            40+
          </span>
          <h2 className="text-base font-normal leading-relaxed text-[#1e212c]">
            Toshkentdagi Xususiy Maktablar
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Hero;
