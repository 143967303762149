import React from "react";
import { WhyComments, WhyLayers, WhyLike, WhyStar } from "../../../img/svgs";

import WhyIllus from "../../../img/WhyIllus.svg";

const Why = () => {
  return (
    <section className="my-[180px]">
      <div className="container flex flex-col gap-10">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
              Nega MaktabHub?
            </span>
            <h1 className="text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
              Keling ko‘rib chiqamiz!
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent px-5 py-2.5 hover:border-[#1089ff]">
            <WhyStar />
            <h2 className="text-center text-base font-bold leading-relaxed text-[#999ca4] transition hover:text-[#1089ff]">
              Yuqori sifatli maktablar katalogi
            </h2>
          </div>
          <div className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent px-5 py-2.5 transition hover:border-[#1089ff]">
            <WhyLike />
            <h2 className="text-center text-base font-bold leading-relaxed text-[#999ca4] transition hover:text-[#1089ff]">
              Oson va tez qidiruv
            </h2>
          </div>
          <div className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent px-5 py-2.5 hover:border-[#1089ff]">
            <WhyLayers />
            <h2 className="text-center text-base font-bold leading-relaxed text-[#999ca4] transition hover:text-[#1089ff]">
              Maktablar haqida ishonchli ma'lumot
            </h2>
          </div>
          <div className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent px-5 py-2.5 hover:border-[#1089ff]">
            <WhyComments />
            <h2 className="text-center text-base font-bold leading-relaxed text-[#999ca4] transition hover:text-[#1089ff]">
              Foydalanuvchilarning real fikrlari
            </h2>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex w-full max-w-[555px] flex-col gap-6">
            <h2 className="text-[32px] font-black leading-[41.60px] text-[#1e212c]">
              Yuqori sifatli maktablar katalogi
            </h2>
            <span className="text-base font-normal leading-relaxed text-[#414450]">
              TalimHub sizga eng yaxshi xususiy va xalqaro maktablarni tanlashda
              yordam beradi. Biz faqat sifatli ta'lim beradigan maktablarni
              taqdim etamiz.
            </span>
          </div>

          <div className="flex w-full max-w-[495px] items-center justify-center">
            <img src={WhyIllus} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
