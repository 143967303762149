import React from "react";
import MissIllus from "../../../img/missIllus.svg";

const Miss = () => {
  return (
    <section className="relative bg-[#1089ff33] py-28">
      <img
        className="absolute bottom-0 left-0 w-[250px]"
        src={MissIllus}
        alt="Illustration"
      />
      <img
        className="absolute bottom-0 right-0 w-[250px]"
        src={MissIllus}
        alt="Illustration"
      />

      <div className="container flex flex-col gap-10">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
              MaktabHub bilan hamkorlik?
            </span>
            <h1 className="max-w-[800px] text-center text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
              Hamkorlik qilmoqchimisiz? Unda hoziroq biz bilan bog‘laning!
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5">
          <input
            placeholder="Telefon raqamingizni kiriting..."
            className="flex w-[400px] rounded-full px-4 py-3 outline-blue-500"
            type="text"
          />
          <button className="flex flex-col items-center justify-start gap-2.5 rounded-full bg-[#1089ff] px-10 transition hover:from-[#3282b8] hover:to-[#0f4c75]">
            <span className="text-center text-base font-bold leading-[52px] tracking-wide text-white">
              Bog'lanish
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Miss;
