import React from "react";
import SchoolCardLarge from "../../cards/SchoolCardLarge";

const SchoolSection = () => {
  return (
    <section className="my-[120px]">
      <div className="container flex flex-col gap-10">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
              Hoziroq barcha maktablarimizni ko'ring
            </span>
            <h1 className="text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
              Barcha maktablar
            </h1>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center gap-7">
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
          <SchoolCardLarge />
        </div>
      </div>
    </section>
  );
};

export default SchoolSection;
