import React from "react";

const SchoolCardLarge = () => {
  return (
    <div className="flex w-full max-w-[350px] flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="w-full">
        <img
          className="w-full"
          src="https://telegra.ph/file/0cb9523cf71d06d7a376e.jpg"
          alt=""
        />
      </div>
      <div className="flex flex-col items-start gap-3 p-4">
        <span className="flex items-center justify-start gap-2.5 rounded bg-[#03cea4] px-2 py-px text-sm font-normal text-white">
          Toshkent
        </span>
        <span className="text-xl font-bold text-[#1e212c]">
          The Ultimate Google Ads Training Course
        </span>
        <div className="text-lg font-normal text-[#787a80]">Info</div>
      </div>
    </div>
  );
};

export default SchoolCardLarge;
