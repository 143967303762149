import React from "react";
import { ReviewsArrow } from "../../../img/svgs";

const Reviews = () => {
  return (
    <section className="mt-[180px] bg-[#f4f5f6] py-28">
      <div className="container flex flex-col gap-10">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
              Fikr mulohazalar
            </span>
            <h1 className="text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
              Foydalanuvchilar fikrlari
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-between gap-20">
          <button className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full transition hover:bg-blue-500">
            <ReviewsArrow />
          </button>

          <div className="flex w-full max-w-[1020px] flex-col gap-6 rounded bg-white px-24 py-16 shadow">
            <span className="text-xl font-normal leading-[30px] text-[#414450]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo,
              amet lectus quam viverra mus lobortis fermentum amet, eu. Pulvinar
              eu sed purus facilisi. Vitae id turpis tempus ornare turpis quis
              non. Congue tortor in euismod vulputate etiam eros. Pulvinar neque
              pharetra arcu diam maecenas diam integer in.
            </span>

            <div className="flex items-center gap-5">
              <img
                className="w-16 rounded-full"
                src="https://www.sweetsss.ru/images/experts/expert-2.jpg"
                alt="Profile"
              />
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#1e212c]">
                  Eleanor Pena
                </span>
                <span className="text-sm font-normal text-[#787a80]">
                  Position, Course
                </span>
              </div>
            </div>
          </div>

          <button className="flex h-8 w-8 shrink-0 rotate-180 items-center justify-center rounded-full transition hover:bg-blue-500">
            <ReviewsArrow />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
