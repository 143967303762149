const AboutMark = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99984 15.3337C12.0432 15.3337 15.3332 12.044 15.3332 8.00033C15.3332 7.30238 15.2357 6.61458 15.0428 5.95588C14.9478 5.63197 14.6096 5.44592 14.2848 5.54096C13.9606 5.6357 13.7751 5.97512 13.8698 6.29903C14.0296 6.84629 14.1109 7.4189 14.1109 8.00033C14.1109 11.3699 11.3695 14.1114 7.99984 14.1114C4.63022 14.1114 1.88873 11.3699 1.88873 8.00033C1.88873 4.63071 4.63022 1.88921 7.99984 1.88921C9.22445 1.88921 10.4027 2.24729 11.4071 2.92449C11.6866 3.11323 12.0668 3.03908 12.2552 2.75948C12.4438 2.47959 12.3703 2.09973 12.0904 1.91115C10.8834 1.09728 9.46898 0.666992 7.99984 0.666992C3.95645 0.666992 0.666504 3.95664 0.666504 8.00033C0.666504 12.044 3.95645 15.3337 7.99984 15.3337Z"
        fill="#97DEFF"
      />
      <path
        d="M14.8617 1.52885L7.99971 8.3905L5.8044 6.19552C5.54399 5.93526 5.12211 5.93526 4.86169 6.19552C4.60144 6.45593 4.60144 6.87781 4.86169 7.13822L7.52836 9.80489C7.65873 9.93526 7.82898 10.0002 7.99971 10.0002C8.17045 10.0002 8.3407 9.93526 8.47107 9.80489L15.8044 2.47156C16.0647 2.21114 16.0647 1.78927 15.8044 1.52885C15.544 1.2686 15.1221 1.2686 14.8617 1.52885Z"
        fill="#97DEFF"
      />
    </svg>
  );
};

const WhyStar = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.27932 1.46709C7.74248 0.398992 9.25719 0.39899 9.72036 1.46709L11.1214 4.69809L14.6273 5.03216C15.7862 5.14259 16.2543 6.58317 15.3816 7.35373L12.7417 9.68467L13.5073 13.1221C13.7604 14.2585 12.535 15.1488 11.5325 14.5569L8.49984 12.7665L5.46721 14.5569C4.46469 15.1488 3.23926 14.2585 3.49236 13.1221L4.25801 9.68467L1.61809 7.35373C0.745398 6.58317 1.21347 5.14259 2.37242 5.03216L5.87824 4.69809L7.27932 1.46709ZM9.90091 5.22735L8.49984 1.99635L7.09876 5.22735C6.906 5.67189 6.48678 5.97647 6.00444 6.02243L2.49861 6.35649L5.13852 8.68743C5.50174 9.00813 5.66186 9.50095 5.55652 9.97389L4.79088 13.4114L7.82351 11.621C8.24075 11.3746 8.75893 11.3746 9.17617 11.621L12.2088 13.4114L11.4432 9.97389C11.3378 9.50095 11.4979 9.00813 11.8612 8.68743L14.5011 6.35649L10.9952 6.02243C10.5129 5.97647 10.0937 5.67189 9.90091 5.22735Z"
        fill="#9A9CA5"
      />
    </svg>
  );
};

const WhyLike = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9547 2.16732C9.86168 2.16732 9.77547 2.21676 9.72843 2.29736L9.72433 2.30438L6.66683 7.26115V13.834H12.3907C12.5135 13.834 12.6198 13.7487 12.6464 13.629L12.6487 13.6186L14.3487 6.97883C14.3798 6.8179 14.2565 6.66732 14.0915 6.66732H11.5002C11.066 6.66732 10.6268 6.52228 10.307 6.21014C9.97075 5.88199 9.81488 5.41203 9.91491 4.90572L10.3941 2.48001C10.4261 2.31806 10.3022 2.16732 10.1372 2.16732H9.9547ZM5.3335 13.834V7.73875H2.97118C2.82739 7.73875 2.7104 7.85476 2.70929 7.99872C2.70929 7.99872 2.70929 7.99872 2.70929 7.99872L2.66684 13.5701C2.66572 13.7154 2.78327 13.834 2.92868 13.834H5.3335ZM5.62809 6.40541L8.58155 1.61731C8.86843 1.13222 9.39032 0.833984 9.9547 0.833984H10.1372C11.1424 0.833984 11.897 1.75237 11.7022 2.73844L11.223 5.16415C11.2144 5.20732 11.22 5.22693 11.2213 5.23124C11.2228 5.23604 11.226 5.24402 11.2383 5.25596C11.2661 5.28317 11.3492 5.33398 11.5002 5.33398H14.0915C15.1121 5.33398 15.8701 6.27923 15.6487 7.27539L15.6464 7.28579L13.9453 13.93C13.7788 14.6537 13.1343 15.1673 12.3907 15.1673H2.92868C2.04298 15.1673 1.32676 14.4457 1.33354 13.56C1.33354 13.56 1.33354 13.56 1.33354 13.56L1.37599 7.98854C1.38271 7.11235 2.09485 6.40541 2.97118 6.40541H5.62809Z"
        fill="#9A9CA5"
      />
    </svg>
  );
};

const WhyLayers = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9547 2.16732C9.86168 2.16732 9.77547 2.21676 9.72843 2.29736L9.72433 2.30438L6.66683 7.26115V13.834H12.3907C12.5135 13.834 12.6198 13.7487 12.6464 13.629L12.6487 13.6186L14.3487 6.97883C14.3798 6.8179 14.2565 6.66732 14.0915 6.66732H11.5002C11.066 6.66732 10.6268 6.52228 10.307 6.21014C9.97075 5.88199 9.81488 5.41203 9.91491 4.90572L10.3941 2.48001C10.4261 2.31806 10.3022 2.16732 10.1372 2.16732H9.9547ZM5.3335 13.834V7.73875H2.97118C2.82739 7.73875 2.7104 7.85476 2.70929 7.99872C2.70929 7.99872 2.70929 7.99872 2.70929 7.99872L2.66684 13.5701C2.66572 13.7154 2.78327 13.834 2.92868 13.834H5.3335ZM5.62809 6.40541L8.58155 1.61731C8.86843 1.13222 9.39032 0.833984 9.9547 0.833984H10.1372C11.1424 0.833984 11.897 1.75237 11.7022 2.73844L11.223 5.16415C11.2144 5.20732 11.22 5.22693 11.2213 5.23124C11.2228 5.23604 11.226 5.24402 11.2383 5.25596C11.2661 5.28317 11.3492 5.33398 11.5002 5.33398H14.0915C15.1121 5.33398 15.8701 6.27923 15.6487 7.27539L15.6464 7.28579L13.9453 13.93C13.7788 14.6537 13.1343 15.1673 12.3907 15.1673H2.92868C2.04298 15.1673 1.32676 14.4457 1.33354 13.56C1.33354 13.56 1.33354 13.56 1.33354 13.56L1.37599 7.98854C1.38271 7.11235 2.09485 6.40541 2.97118 6.40541H5.62809Z"
        fill="#9A9CA5"
      />
    </svg>
  );
};

const WhyComments = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6153 7.04557C14.5204 7.70339 14.2954 8.32861 13.9619 8.90355C13.9869 9.0459 13.9997 9.18978 13.9997 9.33429C13.9997 9.83617 13.8543 10.3165 13.5849 10.7529C13.4504 10.9707 13.4158 11.226 13.4025 11.3946C13.3873 11.5879 13.3924 11.7994 13.4062 12.0025C13.4217 12.2304 13.4497 12.4697 13.4818 12.6954C13.2216 12.6196 12.9395 12.5434 12.6704 12.4826C12.4465 12.4321 12.2174 12.3891 12.0099 12.3676C11.8289 12.349 11.5674 12.3345 11.3335 12.4101C10.8221 12.5754 10.2594 12.6676 9.66636 12.6676C8.96109 12.6676 8.31335 12.5424 7.74559 12.325C7.60903 12.3312 7.47146 12.3343 7.33301 12.3343C6.52993 12.3343 5.75701 12.2287 5.03385 12.0335C6.08516 13.2494 7.81828 14.001 9.66636 14.001C10.3883 14.001 11.0812 13.8901 11.7198 13.6864C11.7209 13.6865 11.723 13.6864 11.726 13.6864C11.7424 13.686 11.7879 13.6851 11.8729 13.6939C12.0067 13.7077 12.1786 13.7385 12.377 13.7833C12.7719 13.8724 13.2157 14.0038 13.5615 14.1136C14.2949 14.3464 15.0056 13.7064 14.8745 12.9621C14.8181 12.6418 14.7595 12.2508 14.7365 11.9122C14.7249 11.7419 14.7236 11.6021 14.7317 11.4995C14.7351 11.457 14.7394 11.4304 14.7421 11.4162C15.1172 10.7949 15.333 10.0874 15.333 9.33429C15.333 8.48526 15.0693 7.70995 14.6153 7.04557Z"
        fill="#9A9CA5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.73109 3.79943C2.83245 4.48662 2.33306 5.38871 2.33306 6.33398C2.33306 6.80534 2.45577 7.25932 2.68599 7.68147C2.77367 7.84226 2.79854 8.00812 2.80744 8.1204C2.81714 8.24283 2.8123 8.36867 2.80166 8.48633C2.78034 8.72191 2.72949 8.98884 2.66849 9.25174C2.59715 9.55927 2.50572 9.88724 2.41296 10.1952C2.76512 10.0672 3.15003 9.93601 3.51493 9.82956C3.80985 9.74352 4.10767 9.66886 4.37274 9.62695C4.60108 9.59084 4.93243 9.55536 5.2177 9.65405C5.85792 9.87555 6.57411 10.0007 7.33306 10.0007C8.76451 10.0007 10.0358 9.55621 10.935 8.86854C11.8337 8.18135 12.3331 7.27926 12.3331 6.33398C12.3331 5.38871 11.8337 4.48662 10.935 3.79943C10.0358 3.11176 8.76451 2.66732 7.33306 2.66732C5.90162 2.66732 4.63034 3.11176 3.73109 3.79943ZM2.92116 2.74028C4.07283 1.85959 5.6349 1.33398 7.33306 1.33398C9.03123 1.33398 10.5933 1.85959 11.745 2.74028C12.8973 3.62145 13.6664 4.88602 13.6664 6.33398C13.6664 7.78194 12.8973 9.04652 11.745 9.92769C10.5933 10.8084 9.03123 11.334 7.33306 11.334C6.43769 11.334 5.58244 11.188 4.80591 10.9224C4.80513 10.9246 4.74215 10.9184 4.58098 10.9439C4.39521 10.9733 4.15809 11.0308 3.88836 11.1095C3.35138 11.2662 2.74835 11.4878 2.28902 11.6666C1.49327 11.9763 0.682633 11.212 0.95074 10.3979C1.09284 9.96647 1.26043 9.42121 1.36966 8.95041C1.42468 8.71324 1.46039 8.51385 1.47375 8.36618C1.47839 8.31492 1.47958 8.27756 1.47932 8.2523C1.17246 7.66592 0.99973 7.01748 0.99973 6.33398C0.99973 4.88602 1.76887 3.62145 2.92116 2.74028Z"
        fill="#9A9CA5"
      />
    </svg>
  );
};

const ReviewsArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.2032 17.2071C8.81268 17.5976 8.17951 17.5976 7.78899 17.2071L3.28899 12.7071C2.89846 12.3166 2.89846 11.6834 3.28899 11.2929L7.78899 6.79289C8.17951 6.40237 8.81268 6.40237 9.2032 6.79289C9.59373 7.18342 9.59373 7.81658 9.2032 8.20711L6.41031 11L19.9961 11C20.5484 11 20.9961 11.4477 20.9961 12C20.9961 12.5523 20.5484 13 19.9961 13L6.41031 13L9.2032 15.7929C9.59373 16.1834 9.59373 16.8166 9.2032 17.2071Z"
        fill="#424551"
      />
    </svg>
  );
};

const FooterPhone = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.66683 1.94857C5.11454 1.94857 4.66683 2.39628 4.66683 2.94857V13.0527C4.66683 13.605 5.11454 14.0527 5.66683 14.0527H10.3335C10.8858 14.0527 11.3335 13.605 11.3335 13.0527V2.94857C11.3335 2.39628 10.8858 1.94857 10.3335 1.94857H10.2779L10.1155 2.3382C10.012 2.58661 9.76928 2.74842 9.50016 2.74842H6.50016C6.23105 2.74842 5.98832 2.58661 5.8848 2.3382L5.72242 1.94857H5.66683ZM3.3335 2.94857C3.3335 1.6599 4.37817 0.615234 5.66683 0.615234H10.3335C11.6222 0.615234 12.6668 1.6599 12.6668 2.94857V13.0527C12.6668 14.3414 11.6222 15.3861 10.3335 15.3861H5.66683C4.37816 15.3861 3.3335 14.3414 3.3335 13.0527V2.94857Z"
        fill="white"
      />
    </svg>
  );
};

const FooterMail = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1668 11.9993V4.66602C15.1668 3.56145 14.2714 2.66602 13.1668 2.66602L2.83349 2.66603C1.72893 2.66603 0.833496 3.56146 0.833496 4.66603V11.9993C0.833496 13.1039 1.72893 13.9993 2.8335 13.9993L13.1668 13.9993C14.2714 13.9993 15.1668 13.1039 15.1668 11.9993ZM13.8335 6.17232V11.9993C13.8335 12.3675 13.535 12.666 13.1668 12.666L2.8335 12.666C2.46531 12.666 2.16683 12.3675 2.16683 11.9993L2.16683 6.17223L6.87561 9.3742C7.55433 9.83573 8.44613 9.83573 9.12485 9.3742L13.8335 6.17232ZM13.8259 4.56509C13.7773 4.24479 13.5007 3.99935 13.1668 3.99935L2.83349 3.99936C2.49965 3.99936 2.22311 4.24476 2.17443 4.56501L7.62535 8.27164C7.85159 8.42548 8.14886 8.42548 8.3751 8.27164L13.8259 4.56509Z"
        fill="white"
      />
    </svg>
  );
};

export {
  AboutMark,
  WhyStar,
  WhyLike,
  WhyComments,
  WhyLayers,
  ReviewsArrow,
  FooterPhone,
  FooterMail,
};
