import React from "react";
import Header from "../../components/header/Header";
import Hero from "../../components/homepage/hero/Hero";
import About from "../../components/homepage/about/About";
import TopSchools from "../../components/homepage/topSchools/TopSchools";
import Why from "../../components/homepage/why/Why";
import Reviews from "../../components/homepage/reviews/Reviews";
import Miss from "../../components/homepage/miss/Miss";
import Footer from "../../components/footer/Footer";

const Home = () => {
  return (
    <>
      <Header />

      <Hero />
      <About />
      <TopSchools />
      <Why />
      <Reviews />
      <Miss />
      
      <Footer />
    </>
  );
};

export default Home;
